import '@scss/main.scss'

import 'picturefill'
import 'lazysizes'

import Accordino from './libs/Accordino'
import Cookizi from './libs/Cookizi'

document.addEventListener('DOMContentLoaded', function() {

    // Hide contentinfo on click
    const contentinfo = document.querySelector('.contentinfo')
    if(contentinfo) {
        contentinfo.addEventListener('click', () => {
            contentinfo.classList.add('contentinfo--hidden')
        })
	}
	
	document.querySelectorAll('.accordino').forEach(accordino => {
		new Accordino(accordino)
	})

    // // Keep utm queries on all link
	// const urlParams = new URLSearchParams(window.location.search);
	// const utm_source = urlParams.get('utm_source');
	// const utm_medium = urlParams.get('utm_medium');
	// const utm_campaign = urlParams.get('utm_campaign');

	// var links = document.getElementsByTagName('a'),
	// utmRegExp = /(\&|\?)utm_[A-Za-z]+=[A-Za-z0-9]+/gi,
	// utms = [];
	// if(utm_source){
	// 	utms.push("utm_source=" + utm_source);
	// } 
	// if(utm_medium){
	// 	utms.push("utm_medium=" + utm_medium);
	// } 
	// if(utm_campaign){
	// 	utms.push("utm_campaign=" + utm_campaign);
	// } 
	// if(utms) {
	// 	for (var index = 0; index < links.length; index += 1) {
	// 		var tempLink = links[index].href,
	// 		tempParts;
	// 		var pathArray = tempLink.split("/");

	// 		if(!links[index].classList.contains('m-nav-main__link--external') && pathArray['3'] != 'admin') {
	// 			tempLink = tempLink.replace(utmRegExp, "");
				 
	// 			tempParts = tempLink.split("#");
				 
	// 			if (tempParts[0].indexOf("?") < 0 ) {
	// 			 	tempParts[0] += "?" + utms.join("&"); // The script adds UTM parameters to all links with the domain you've defined
	// 			} else {
	// 				tempParts[0] += "&" + utms.join("&");
	// 			}
				 
	// 			tempLink = tempParts.join("#");
	// 		links[index].href = tempLink;
	// 		}
	// 	 }
	// }
	// Cookizi
    const cookizi = new Cookizi({
        types: ['analytics']
	})
	if(document.querySelector('.m-nav-footer')) {
		document.querySelector('.m-nav-footer__item:last-child a').addEventListener('click', e => {
			e.preventDefault()
			cookizi.showSidebar()
		})
	}
})

// SWPL LOG
console.log('%c S W P L ', 'background: #1467ff; color: white; font-size: 17px; font-weight: bold; line-height: 36px; text-align: center', ' www.swpl.fr');
